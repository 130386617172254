import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./components/app/App";
import { ThemeProvider, createTheme } from "@mui/material";
import { Provider } from "react-redux";
import store from "./store";

const theme = createTheme({
    palette: {
        primary: {
            main: "#b42427", // red
        },
        secondary: {
            main: "#181818", // gray
        },
        gold: {
            main: "#fdbb53", // gold
        },
        zombieGreen: {
            main: "#67b402", // green
        },
        // button disabled
        action: {
            disabledBackground: "rgb(255 255 255 / 15%)",
            disabled: "rgb(151 147 147 / 96%)",
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <App />
        </Provider>
    </ThemeProvider>
);
