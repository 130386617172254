import "./Header.scss";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../axios/axios";

import { cutEmail } from "../utils/cutEmail";

import { setIsAuth, setLoading } from "../../store/authSlice";
import { setUser } from "../../store/userSlice";
import { setNewCatBtn, setProcessPlay } from "../../store/gameProcessSlice";

import Button from "@mui/material/Button";

const Header = () => {
    const dispatch = useDispatch();

    const isAuth = useSelector((state) => state.authorization.isAuth);
    const user = useSelector((state) => state.user.user);

    // set axios request
    const logoutRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    logoutRequest.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
            "token"
        )}`;
        return config;
    });

    useEffect(() => {
        if (localStorage.getItem("token")) {
            checkAuth();
        } else {
            dispatch(setIsAuth(false));
            dispatch(setLoading(false));
        }
    }, []);

    // battle was the current cat?
    const battleCurrentUserCatOver = (battle) => {
        if (battle) {
            dispatch(setNewCatBtn(false));
            dispatch(setProcessPlay(true));
        } else {
            dispatch(setNewCatBtn(true));
            dispatch(setProcessPlay(false));
        }
    };

    const logout = () => {
        logoutRequest
            .post("/logout")
            .then((res) => {
                localStorage.removeItem("token");
                dispatch(setIsAuth(false));
                dispatch(setUser({}));
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
            });
    };

    const checkAuth = async () => {
        dispatch(setLoading(true));
        axios
            .get(`${baseUrl}/refresh`, {
                withCredentials: true,
            })
            .then((res) => {
                // console.log(res.data);
                localStorage.setItem("token", res.data.accessToken);
                dispatch(setIsAuth(true));
                dispatch(setUser(res.data.user));
                battleCurrentUserCatOver(res.data.user.battleOver);
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                dispatch(setIsAuth(false));
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    return (
        <div className="headerWrapper">
            <Link to="/" className="headerLogo">
                <div className="headerLogoWrapper">
                    <img
                        src="/img/logo(200x200).png"
                        alt="blood hand"
                        className="headerLogoImg"
                    />
                    <div className="headerLogoText">Z-game</div>
                    <div className="headerMessage">&bull; beta version</div>
                </div>
            </Link>

            <div className="headerLinkWrapper">
                {/* <Link to="/" className="headerLink">
                    Main
                </Link>

                <Link to="/game" className="headerLink">
                    Game
                </Link>

                <Link to="/payments" className="headerLink">
                    Payments
                </Link>

                <Link to="/lottery" className="headerLink">
                    Lottery
                </Link>

                <Link to="/faucet" className="headerLink">
                    Faucet
                </Link>

                <Link to="/referral" className="headerLink">
                    Referral program
                </Link>

                <Link to="/docs" className="headerLink">
                    Docs
                </Link> */}
            </div>

            <div className="headerAccount">
                {/* {isAuth ? (
                    <>
                        <img
                            src="/img/blood-spot.png"
                            alt="blood spot"
                            className="headerAccountImg"
                        />
                        <div className="headerAccountName">
                            {cutEmail(user.userEmail)}
                        </div>
                        <Button
                            className="headerAccountBtn"
                            variant="contained"
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </>
                ) : null} */}
            </div>

            <img
                className="headerBloodImg"
                src="/img/long-blood.png"
                alt="blood"
            ></img>
        </div>
    );
};

export default Header;
