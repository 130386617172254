import "./Plug.scss";

import Container from "@mui/material/Container";

const Plug = () => {
    return (
        <Container>
            <img
                className="plugImg"
                src="/img/zombieComp500.png"
                alt="zombie with computer"
            />
            <div className="plugText">
                A game with more zombies and the <span>ZOMB</span> token will be
                here soon!
            </div>
        </Container>
    );
};

export default Plug;
