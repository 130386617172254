import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import userReducer from "./userSlice";
import gameProcessReducer from "./gameProcessSlice";
import gameStatsReducer from "./gameStatsSlice";

export default configureStore({
    reducer: {
        authorization: authReducer,
        user: userReducer,
        gameProcess: gameProcessReducer,
        gameStats: gameStatsReducer,
    },
});
