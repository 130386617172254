import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {},
    },
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        setNewCat(state, action) {
            state.user.cat = action.payload.cat;
            state.user.battleOver = action.payload.battleOver;
        },
        setBattle(state, action) {
            state.user.enemy = action.payload.enemy;
            state.user.balance = action.payload.balance;
            state.user.enemyScore = action.payload.enemyScore;
            state.user.tickets = action.payload.lottery.newTickets;
            state.user.turnover = action.payload.lottery.newTurnover;
        },
        setDepositBalance(state, action) {
            state.user.balance = action.payload.newBalance;
            state.user.lastTimeTransaction = action.payload.transactionDateTo;
        },
        setWithdrawBalance(state, action) {
            state.user.balance = action.payload.newBalance;
            state.user.lastTimeTransaction =
                action.payload.newLastTimeTransaction;
            state.user.userPlayAllowed = true;
        },
        setPlayAllowed(state, action) {
            state.user.userPlayAllowed = action.payload;
        },
        setBalance(state, action) {
            state.user.balance = action.payload.balance;
        },
        setRefBalance(state, action) {
            state.user.balance = action.payload.balance;
            state.user.referralBalance = action.payload.referralBalance;
        },
    },
});

export const {
    setUser,
    setNewCat,
    setBattle,
    setDepositBalance,
    setWithdrawBalance,
    setPlayAllowed,
    setBalance,
    setRefBalance,
} = userSlice.actions;

export default userSlice.reducer;
