import "../statsContent/StatsContent.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import StatsContent from "../statsContent/StatsContent";
import Plug from "../plug/Plug";
import WarningMessage from "../warningMessage/WarningMessage";
import LoginRegister from "../loginRegister/LoginRegister";

import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const MainPage = () => {
    const isAuth = useSelector((state) => state.authorization.isAuth);

    const navigate = useNavigate();

    const toGamePage = () => {
        navigate("/game");
    };

    return (
        <>
            <Container>
                <div className="mainTitleWrapper">
                    {/* <img
                        className="mainTitleImg"
                        src="/img/hit-3.png"
                        alt="blood strike"
                    ></img> */}
                    <div className="mainTitle">Z-game</div>
                    {/* <img
                        className="mainTitleImg"
                        src="/img/blood-hand.png"
                        alt="blood strike"
                    ></img> */}
                </div>

                {/* <StatsContent /> */}
                <Plug />

                {/* write in DB and display here */}
                {/* <WarningMessage /> */}

                {/* {!isAuth ? (
                    <LoginRegister />
                ) : (
                    <Button
                        className="playGameBtn"
                        variant="contained"
                        onClick={toGamePage}
                    >
                        Play now!
                    </Button>
                )} */}
            </Container>
        </>
    );
};

export default MainPage;
